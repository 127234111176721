var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "content-header" },
        [
          _c(
            "b-col",
            {
              staticClass: "content-header-left mb-2",
              attrs: { cols: "12", md: "9" }
            },
            [
              _c(
                "b-row",
                { staticClass: "breadcrumbs-top" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c(
                      "h2",
                      {
                        staticClass: "content-header-title float-left pr-1 mb-0"
                      },
                      [_vm._v(" Reports ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "breadcrumb-wrapper" },
                      [
                        _c(
                          "b-breadcrumb",
                          [
                            _c(
                              "b-breadcrumb-item",
                              { attrs: { to: "/apps/reports/type" } },
                              [
                                _c("feather-icon", {
                                  staticClass: "align-text-top",
                                  attrs: { icon: "HomeIcon", size: "16" }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-breadcrumb-item",
                              { on: { click: _vm.goBack } },
                              [_vm._v(" Reports ")]
                            ),
                            _c("b-breadcrumb-item", { attrs: { active: "" } }, [
                              _vm._v(" Trespass Notice ")
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.reportData &&
      _vm.clientOptions &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Report Details ")])]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.report_type,
                                        expression: "reportData.report_type"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "report_type"
                                    },
                                    domProps: {
                                      value: _vm.reportData.report_type
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "report_type",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.time_zone,
                                        expression: "reportData.time_zone"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "time_zone"
                                    },
                                    domProps: {
                                      value: _vm.reportData.time_zone
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "time_zone",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.reportData.client_display_name,
                                        expression:
                                          "reportData.client_display_name"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "client_display_name"
                                    },
                                    domProps: {
                                      value: _vm.reportData.client_display_name
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "client_display_name",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reportData.attachment_list,
                                        expression: "reportData.attachment_list"
                                      }
                                    ],
                                    attrs: {
                                      type: "hidden",
                                      name: "attachment_list"
                                    },
                                    domProps: {
                                      value: _vm.reportData.attachment_list
                                    },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.reportData,
                                          "attachment_list",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report ID",
                                                    "label-for": "report_id"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData.report_id
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Report Date",
                                                    "label-for":
                                                      "report_date_local"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .report_date_local
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportData.report_id
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Submitted By",
                                                    "label-for":
                                                      "user_display_name"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.reportData
                                                          .user_display_name
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Client",
                                                "label-for": "clientname"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clientname",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.clientOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "clientname"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectClient
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .clientname,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "clientname",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.clientname"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site",
                                                "label-for": "sitename"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sitename",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.siteOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "sitename"
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.handleSelectSite
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .sitename,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "sitename",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.sitename"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Site Address",
                                                "label-for": "site_address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "site_address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "site_address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .site_address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "site_address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.site_address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Name",
                                                "label-for": "name_first"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "name_first",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "name_first"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .name_first,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "name_first",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.name_first"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Initial",
                                                "label-for": "name_middle"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "name_middle",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "name_middle"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .name_middle,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "name_middle",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.name_middle"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Last Name",
                                                "label-for": "name_last"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "name_last",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "name_last"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .name_last,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "name_last",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.name_last"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date Of Birth",
                                                "label-for": "birth_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "birth_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id: "birth_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .birth_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "birth_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.birth_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Gender",
                                                "label-for": "gender"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "gender",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.genderOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "gender"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .gender,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "gender",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.gender"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Address",
                                                "label-for": "address"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "address",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "address"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .address,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "address",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.address"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "City",
                                                "label-for": "city"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "city",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "city"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .city,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "city",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.city"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Province",
                                                "label-for": "province"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "province",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "province"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .province,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "province",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.province"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Postal code",
                                                "label-for": "postal_code"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "postal_code",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "postal_code"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .postal_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "postal_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.postal_code"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Country",
                                                "label-for": "country"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "country",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "country"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .country,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "country",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.country"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Complexion",
                                                "label-for": "complexion"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "complexion",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "complexion"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .complexion,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "complexion",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.complexion"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Hair Colour",
                                                "label-for": "hair_colour"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "hair_colour",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "hair_colour"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .hair_colour,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "hair_colour",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.hair_colour"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Hair Length",
                                                "label-for": "hair_length"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "hair_length",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "hair_length"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .hair_length,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "hair_length",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.hair_length"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Height",
                                                "label-for": "height"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "height",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "height"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .height,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "height",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.height"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Weight",
                                                "label-for": "weight"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "weight",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "weight"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .weight,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "weight",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.weight"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Glasses",
                                                "label-for": "glasses"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "glasses",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "glasses"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .glasses,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "glasses",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.glasses"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Clothing",
                                                "label-for": "clothing"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "clothing",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "clothing"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .clothing,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "clothing",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.clothing"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Marks",
                                                "label-for": "marks"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "marks",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "marks"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .marks,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "marks",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.marks"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Tattoos",
                                                "label-for": "tattoos"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "tattoos",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "tattoos"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .tattoos,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "tattoos",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.tattoos"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Issued at",
                                                "label-for": "issued_at"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "issued_at",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "issued_at"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .issued_at,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "issued_at",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.issued_at"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date",
                                                "label-for": "issued_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "issued_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id: "issued_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .issued_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "issued_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.issued_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Period Of Time",
                                                "label-for": "period_of_time"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "period_of_time",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "period_of_time"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .period_of_time,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "period_of_time",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.period_of_time"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2 mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Trespass Offence",
                                                "label-for": "offence_list"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "offence_list" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "offence_list_entry_prohibited",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .offence_list
                                                                        .entry_prohibited,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .offence_list,
                                                                        "entry_prohibited",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.offence_list.entry_prohibited"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Enter premises when entry prohibited "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "offence_list_prohibited_activity",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .offence_list
                                                                        .prohibited_activity,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .offence_list,
                                                                        "prohibited_activity",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.offence_list.prohibited_activity"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Engaged in prohibited activity "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "b-form-checkbox",
                                                                {
                                                                  staticClass:
                                                                    "mt-2",
                                                                  attrs: {
                                                                    id:
                                                                      "offence_list_fail_to_leave",
                                                                    checked:
                                                                      "true",
                                                                    switch: "",
                                                                    inline: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .reportData
                                                                        .offence_list
                                                                        .fail_to_leave,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .reportData
                                                                          .offence_list,
                                                                        "fail_to_leave",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "reportData.offence_list.fail_to_leave"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Fail to leave when directed "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Reason For Notice",
                                                "label-for": "notice_reason"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "notice_reason",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "notice_reason"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .notice_reason,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "notice_reason",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.notice_reason"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Police Division",
                                                "label-for": "police_division"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "police_division"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "police_division"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.reportData
                                                                  .police_division,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.reportData,
                                                                  "police_division",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "reportData.police_division"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.reportID === null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("create", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [_vm._v(" Add Report ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.reportID !== null
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _vm.$can("update", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick,
                                                            type: "submit"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.$can("read", "reports")
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.printReport
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Print Report "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm.$can("delete", "reports")
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-4" },
                                                [
                                                  !_vm.reportData.approved
                                                    ? _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Approve "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "b-col",
                                                        { attrs: { md: "2" } },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                              attrs: {
                                                                variant:
                                                                  "secondary",
                                                                block: "",
                                                                disabled:
                                                                  _vm.preventDoubleClick
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleApprovalToggle
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Unapprove "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2180814516
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Photos / Images ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openCamera }
                                  },
                                  [_vm._v(" Take Picture ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openImageSelector }
                                  },
                                  [_vm._v(" Add Image ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) === "jpg"
                              ? _c(
                                  "div",
                                  { staticClass: "item-img text-center" },
                                  [
                                    _c("thumbs-lazy-image-component", {
                                      staticClass: "card-img-top",
                                      attrs: {
                                        src: mediaItem.access_url,
                                        "src-placeholder":
                                          "@/assets/images/noImage.png"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            mediaItem.filename.slice(-3) === "jpg" &&
                            _vm.$can("update", _vm.abilityRequired) &&
                            _vm.reportData.username === _vm.userData.username
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteImage(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Delete Image ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "cameraInput",
                  staticStyle: { display: "none" },
                  attrs: {
                    type: "file",
                    accept: "image/*",
                    capture: "environment"
                  },
                  on: { change: _vm.handleImageChange }
                }),
                _c("input", {
                  ref: "imageInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.handleImageChange }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.reportData &&
      _vm.reportID !== null &&
      (_vm.$can("read", "reports") || _vm.$can("update", "reports"))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_c("h5", [_vm._v(" Files ")])]),
              _c("b-card-body", [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-2" },
                      [
                        _vm.reportData &&
                        _vm.clientOptions &&
                        _vm.$can("update", _vm.abilityRequired) &&
                        _vm.reportData.username === _vm.userData.username
                          ? _c(
                              "b-col",
                              { attrs: { md: "2" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                    attrs: {
                                      variant: "primary",
                                      block: "",
                                      disabled: _vm.preventDoubleClick
                                    },
                                    on: { click: _vm.openFileSelector }
                                  },
                                  [_vm._v(" Add File ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.reportData.attachment_list
                  ? _c(
                      "section",
                      { staticClass: "grid-view" },
                      _vm._l(_vm.reportData.attachment_list, function(
                        mediaItem
                      ) {
                        return _c(
                          "b-card",
                          {
                            key: mediaItem.filename,
                            ref: mediaItem.filename,
                            refInFor: true,
                            attrs: { id: mediaItem.filename, "no-body": "" }
                          },
                          [
                            mediaItem.filename.slice(-3) !== "jpg"
                              ? _c(
                                  "b-card-footer",
                                  { staticClass: "pt-1 pb-1 border-dark" },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                attrs: {
                                                  variant: "primary",
                                                  block: "",
                                                  disabled:
                                                    _vm.preventDoubleClick
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.downloadFile(
                                                      mediaItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Download File ")]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm.reportData &&
                                        _vm.clientOptions &&
                                        _vm.$can(
                                          "update",
                                          _vm.abilityRequired
                                        ) &&
                                        _vm.reportData.username ===
                                          _vm.userData.username
                                          ? _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                    attrs: {
                                                      variant: "primary",
                                                      block: "",
                                                      disabled:
                                                        _vm.preventDoubleClick
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteFile(
                                                          mediaItem
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Delete File ")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      }),
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  ref: "fileInput",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "*/*" },
                  on: { change: _vm.handleFileChange }
                })
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }